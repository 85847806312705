<template>
    <dashboard-layout>
        <!-- content body -->
        <div class="content-area__body">
            <!-- settings section -->
            <section class="settings-section section-gap-30">
                <SettingMenu></SettingMenu>
                <div class="setting-content">
                    <div class="section-title-wrap mb-0 d-flex justify-content-between">
                        <div class="section-title margin-deliver">
                            {{ $t(`ai setup`) }}
                            <v-popover offset="10" :disabled="false"  style="display:initial;">
                                <button class="ej__tooltip__button ml-1"> <i class="eicon e-question"></i> </button>
                                <template slot="popover">
                                    <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                    <div style="text-align:left;">
                                        <a v-close-popover href="https://easy.jobs/docs/how-to-configure-ai-setup-in-easy-jobs" target="_blank">
                                            {{$t('How to configure AI?')}}
                                        </a>
                                    </div>

                                </template>
                            </v-popover>
                        </div>
                    </div>
                    <div class="form-box">
                        <div class="ai-releated-settings">
                            <div>
                                <label>{{ $t(`Show AI Score`) }}</label>
                                <div class="mt-3">
                                    <input type="checkbox" class="notify"
                                           v-model="is_ai_enabled"
                                           value="true"
                                           @change="toggleSetup"
                                           id="ai---setup"
                                    />
                                    <label for="ai---setup">
                                        <span v-if="is_ai_enabled">{{ $t('on') }}</span>
                                        <span v-else>{{ $t('off') }}</span>
                                    </label>
                                </div>
                            </div>
                            <div class="mt-3" v-if="is_ai_enabled && !process_already_runned">
                                <label>{{ $t('Run batch process for old candidates.') }}</label>
                                <div class="mt-3">
                                    <button class="button info-button" @click="runBatchProcess()">{{ $t('Run process') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </dashboard-layout>
</template>
<script>
import DashboardLayout from "../../../layouts/DashboardLayout";
import SettingMenu from "../../../components/company/SettingSidebar";
import client from "../../../app/api/Client";
import {mapActions, mapState} from 'vuex';
import {UPDATE_COMPANY_DATA} from "../../../constants/action-type";
import Vue from "vue";

export default {
    components: {
        DashboardLayout,
        SettingMenu,
    },

    data() {
        return {
            is_ai_enabled: false,
            isContentLoading: true,
            process_already_runned: false,
        }
    },

    computed: {
        ...mapState(['user', 'company']),
        hasAIPermission(){
            return this.user.package_rules.easyjobs_ai;
        }
    },
    methods: {
        ...mapActions([UPDATE_COMPANY_DATA]),
        async toggleSetup() {
            try {
                let {data: {data, message}} = await client().put(`/company/setting/ai-setup`, {
                    is_ai_enabled: this.is_ai_enabled
                });

                this[UPDATE_COMPANY_DATA](data);
                this.$toast.success(this.$t(message));
            } catch (error) {
            }
        },
        async getAiSetup() {
            try {
                let {data} = await client().get(`/company/setting/ai-setup`);
                this.is_ai_enabled = data.data.ai_setup_enabled;
                this.process_already_runned = data.data.already_run_batch_score;
            } catch (error) {
            }
        },

        async runBatchProcess() {
            try {
                let {data: {data, message}} = await client().put(`/company/setting/ai-setup`, {
                    run_batch_score: true
                });

                this.process_already_runned = true;
                this.$toast.success(this.$t("Process started ! it's may take long time please wait"));
            } catch (error) {
            }
        }
    },
    async mounted() {
        if (!this.hasAIPermission) {
            this.$toast.error("Sorry you don't have permission for this action.");
            await this.$router.push({name: 'company.setting.basic'});
        }
        await this.getAiSetup();
    }
}
</script>

<style scoped>
input.notify[type=checkbox] {
    display: none;
}

input.notify + label {
    cursor: pointer;
    width: 64px;
    height: 30px;
    background: #FF5F74;
    display: inline-block;
    border-radius: 100px;
    position: relative;
    margin: auto;
}

input.notify + label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

input.notify:checked + label {
    background: #60CE83;
}

input.notify:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

input.notify + label > span {
    color: #fff;
    text-transform: uppercase;
    float: right;
    padding: 8px 8px;
    font-size: .9rem;
    line-height: 1;
}

input.notify:checked + label > span {
    float: left;
}
</style>
